export default {
    methods: {
        checkboxValidator(rule, value, cb) {
            console.log('rule and value:', rule, value)
            if (!value) {
                cb('Field required')
            } else {
                cb()
            }
        }
    }
}
