<template>
    <div class="p-details">
        <el-form
            :model="practiceDetailsForm"
            :rules="validationRules"
            ref="practiceDetailsForm"
            class="pd-form"
        >
            <h4 class="font-display font-semibold pb-4">Practice Details</h4>
            <div class="grid grid-cols-12 gap-2 w-full">
                <div class="col-span-4">
                    <el-form-item prop="name" class="mb-4">
<!--                        <div class="inline-block align-bottom">-->
                        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Practice name</label>
                        <el-input ref="practiceName" v-model="practiceDetailsForm.name" data-cy="practiceName" :readonly="editMode && !isReapplying" @change="nameChangeHandler"></el-input>
                    </el-form-item>
                </div>
                <div class="col-span-3">
                    <el-form-item prop="practiceType" class="mb-4">
                        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Practice type</label>
                        <el-select
                            v-model="practiceDetailsForm.practiceType"
                            class="w-full"
                            data-cy="practiceType"
                            :disabled="editMode"
                        >
                            <el-option
                                v-for="(item, index) in practiceTypes"
                                :key="`practice-type-${index}`"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <!-- <div class="col-span-3">
                    <el-form-item prop="isBranch" class="mb-4">
                        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Branch or main practice</label>
                        <el-select
                            v-model="practiceDetailsForm.isBranch"
                            class="w-full"
                            :disabled="readonly"
                        >
                            <el-option
                                v-for="(item, index) in branchOptions"
                                :key="`branch-${index}`"
                                :label="item.label"
                                :value="item.value"
                                data-cy="select-input">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div> -->
            </div>
            <div v-if="checkPracticeType">
                <div class="grid grid-cols-12 gap-2 w-full">
                    <div
                        class="flex-1 col-span-10 rounded rounded-lg bg-primary-lightest text-sm mt-3 mb-2 px-3 py-3"
                    >
                        <p class="text-sm text-primary">
                            According to the
                            <span class="italic">RACGP general practice</span> is defined as
                        </p>
                        <p class="text-primary text-sm px-6">
                            <span
                                class="italic inline"
                            >the provision of primary continuing comprehensive whole patient medical care to individuals, families and their communities.</span>
                            <el-link
                                type="primary"
                                href="https://www.racgp.org.au/FSDEDEV/media/documents/Running%20a%20practice/Practice%20standards/5th%20edition/Definition-of-general-practice-factsheet.pdf"
                                target="_blank"
                                class="inline uppercase font-bold text-xs ml-2"
                            >read full description ></el-link>
                        </p>
                    </div>
                </div>
                <el-form-item prop="meetsGpDefinition" class="pl-4 mb-5">
                    <el-checkbox
                        v-model="practiceDetailsForm.meetsGpDefinition"
                        label="Our practice meets the definition above"
                        :disabled="readonly"
                    ></el-checkbox>
                </el-form-item>
            </div>
            <div class="my-1" v-if="practiceDetailsForm.practiceType == 'MDS'">
                <el-form-item prop="isAmds" class="mb-4">
                    <span>Is your service an Approved Medical Deputising Service?</span>
                    <el-radio-group v-model="practiceDetailsForm.isAmds">
                        <el-radio :label="true" class="pn-radio" :disabled="readonly">Yes</el-radio>
                        <el-radio :label="false" class="pn-radio" :disabled="readonly">No</el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="grid grid-cols-12 gap-2">
                <div class="flex-1 col-span-2">
                    <el-form-item prop="organisationPtr.abn" class="mb-2">
                        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Practice ABN</label>
                        <el-input
                            v-model.trim="practiceDetailsForm.organisationPtr.abn"
                            :readonly="readonly"
                            @change="abnChangeHandler"
                        ></el-input>
                    </el-form-item>
                </div>
                <div class="flex-1 col-span-2">
                    <el-form-item prop="organisationPtr.structure" class="mb-2">
                        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Structure</label>
                        <el-select
                            v-model="practiceDetailsForm.organisationPtr.structure"
                            class="w-full"
                            :disabled="readonly"
                        >
                            <el-option
                                v-for="(item, index) in structures"
                                :key="`structure-${index}`"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="flex-1 col-span-3" v-if="practiceDetailsForm.organisationPtr.structure === 'OTHER'">
                    <el-form-item prop="organisationPtr.structureOther" class="mb-2">
                        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Structure name</label>
                        <el-input
                            v-model.trim="practiceDetailsForm.organisationPtr.structureOther"
                            class="w-full"
                            :readonly="readonly"
                        ></el-input>
                    </el-form-item>
                </div>
            </div>
            <el-form-item prop="inPracticeNetwork" class="mb-2">
                <div class="inline-block align-middle text-sm">
                    <span>Is this practice part of a practice network?</span>
                    <el-popover
                        placement="top-start"
                        title="Practice network"
                        width="200"
                        trigger="hover"
                        content="Indicates whether a practice or service is part of a network. A network is when more than one practice is owned and/or operated by the same entity, usually with consistent governance arrangements."
                    >
                        <fa-icon icon="question-circle" slot="reference" class="grey-1 ml-2" />
                    </el-popover>
                </div>
                <el-radio-group v-model="practiceDetailsForm.isInPracticeNetwork">
                    <el-radio :label="true" class="pn-radio" :disabled="readonly">Yes</el-radio>
                    <el-radio :label="false" class="pn-radio" :disabled="readonly">No</el-radio>
                </el-radio-group>
            </el-form-item>

            <div class="grid grid-cols-12 gap-2 w-full">
                <div class="flex-1 col-span-4" v-if="practiceDetailsForm.isInPracticeNetwork === true">
                    <el-form-item prop="practiceNetworkName">
                        <label
                            class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight"
                        >Practice network name</label>
                        <el-input v-model="practiceDetailsForm.practiceNetworkName" :readonly="readonly"></el-input>
                    </el-form-item>
                </div>
                <div class="flex-1 col-span-3" v-if="practiceDetailsForm.isInPracticeNetwork === true">
                    <el-form-item prop="practiceNetworkAbn">
                        <label
                            class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight"
                        >Practice network ABN</label>
                        <el-input v-model="practiceDetailsForm.practiceNetworkAbn" :readonly="readonly"></el-input>
                    </el-form-item>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import { mapState } from "vuex"
import abnValidator from '@/components/shared/mixins/abnValidator'
import Validators from '@/components/shared/mixins/validators'

export default {
    name: "PracticeForm",
    mixins: [abnValidator, Validators],
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        editMode: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        isReapplying: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        validate() {
            return this.$refs.practiceDetailsForm.validate()
        },
        resetFields() {
            return this.$refs.practiceDetailsForm.resetFields()
        },
        validateInput(rule, value, cb) {
            // first we check if we are adding or editing
            if (value === null || value === undefined) {
                cb("This field is required")
            } else {
                cb()
            }
        },
        nameChangeHandler(value) {
            this.$emit('change-name', value)
        },
        abnChangeHandler(value) {
            this.$emit('change-abn', value)
        }
    },
    data() {
        return {
            practiceTypesCheck: ['GP', 'ABHS'],
            validationRules: {
                name: [{ required: true, message: 'This field is required', trigger: 'blur' }],
                practiceType: [{ required: true, message: 'This field is required', trigger: 'change' }],
                isBranch: [{ validator: this.validateInput, trigger: 'change' }],
                'organisationPtr.abn': [{ validator: this.abnAcnValidator, trigger: 'blur' }],
                'organisationPtr.structure': [{ required: true, message: 'This field is required', trigger: 'blur' }],
                practiceNetworkName: [{ required: true, message: 'This field is required', trigger: 'blur' }],
                practiceNetworkAbn: [{ validator: this.abnAcnValidator, trigger: 'blur' }],
                meetsGpDefinition: [{ validator: this.checkboxValidator, trigger: 'change' }],
            }
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        checkPracticeType() {
          return this.practiceTypesCheck.includes(this.practiceDetailsForm.practiceType)
        },
        practiceDetailsForm: {
            get() {
                const practice = this.value
                if (practice.isInPracticeNetwork === null || practice.isInPracticeNetwork === undefined) {
                    practice.isInPracticeNetwork = false
                }
                // this.$refs.practiceDetailsForm.clearValidate()
                return practice || {}
            },
            set(practiceDetailsForm) {
                this.$emit('input', practiceDetailsForm)
            }
        },
        practiceTypes() {
            return this.staticChoices["practice.practice_type"] || []
        },
        structures() {
            return this.staticChoices["organisation.structure"] || []
        },
        branchOptions() {
            let branchOptions = this.staticChoices['practice.branch_or_main']
            if (branchOptions !== null && branchOptions !== undefined) {
                branchOptions = [...branchOptions.map(b => {
                    return {...b, value: b.value.toLowerCase() === 'branch'}
                })]
            } else {
                branchOptions = []
            }
            return branchOptions
        }
    }
}
</script>

<style></style>
