<template>
    <div class="flex flex-row float-left" v-if="showGoBack()">
        <router-link :to="goBack" data-cy="goBack" class="group mr-4 flex items-center text-grey-2 hover:text-primary-light">
            <fa-icon icon="chevron-left" class="mr-1 group-hover:text-primary-light"/>
            <span class="text-xs font-bold uppercase group-hover:text-primary-light">BACK</span>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "GoBack",
    props: {
        goBack: {
            type: Object,
            default: () => {}
        },
        forceGoBack: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        showGoBack() {
            // if we are on the Practice Details add page, we need a way to get back to the maintenance page for the single practice
            return this.$auth.user() && this.goBack != null && (!this.$auth.user().singlePractice || this.forceGoBack)
        },
    }
}
</script>
