export default {
    methods: {
        abnAcnValidator(rule, value, cb, isAbn) {
            if (!value) {
                cb('This field is required')
            } else {
                if (!this.validateABN(value)) {
                    cb('Invalid ABN')
                } else{
                    cb()
                }
            }
        },
        validateABN(abn) {
            abn = abn.replace(/ /g, '');
        
            if (abn.length != 11 || isNaN(parseInt(abn))) return false
        
            const weighting = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]
            const firstDigitProcessed = parseInt(abn.charAt(0)) - 1
            let weighted = firstDigitProcessed * weighting[0]
        
            for (let i = 1; i < abn.length; i++) {
                weighted += parseInt(abn.charAt(i)) * weighting[i]
            }
        
            return weighted % 89 === 0
        },
        validateACN(acn) {
            acn = acn.replace(/ /g, '');
    
            if (this.acn.length != 9 || isNaN(parseInt(this.acn))) return false
        
            const weighting = [8, 7, 6, 5, 4, 3, 2, 1]
            let weighted = 0
            for (let i = 0; i < weighting.length; i++) {
                weighted += parseInt(this.acn.charAt(i)) * weighting[i]
            }
            let checkDigit = 10 - weighted % 10
            checkDigit = checkDigit === 10 ? 0 : checkDigit
            return checkDigit === acn[8]
        }
    }
}
