<template>
    <div>
        <el-form
            :model="phoneWebsiteForm"
            ref="phoneWebsiteForm"
            class="pd-form"
        >
            <h2>{{ title || '&nbsp;' }}</h2>
            <div class="grid grid-cols-1 gap-2">
                <div class="mb-2">
                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Phone
                        Number</label>
                    <PhoneNumber
                        name="phone"
                        v-model="phoneWebsiteForm.phone"
                        ref="phone"
                        :readonly="readonly"
                    />
                </div>
                <div class="mb-2">
                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Fax
                        Number</label>
                    <PhoneNumber
                        name="fax"
                        v-model="phoneWebsiteForm.fax"
                        ref="fax"
                        :required="false"
                        :readonly="readonly"
                    />
                </div>
                <el-form-item prop="website" class="mb-2">
                    <label
                        class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Website</label>
                    <el-input
                        name="website"
                        v-model.trim="phoneWebsiteForm.website"
                        :readonly="readonly">
                    </el-input>
                </el-form-item>
            </div>
        </el-form>
    </div>
</template>

<script>
import PhoneNumber from '@/components/shared/PhoneNumber'

export default {
    name: 'phone-website',
    components: { PhoneNumber },
    props: {
        title: {
            type: String,
            default: ''
        },
        value: {
            type: Object,
            required: true,
            default: () => {
                return this.defaultOrganisation
            }
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        validate() {
            return Promise.all([
                this.$refs.phone.validate(),
                this.$refs.fax.validate()
            ])
        },
        validateInput(rule, value, cb) {
            // first we check if we are adding or editing
            if (!value || (!value.phone && !value.countryCode)) {
                cb('This field is required')
            } else {
                cb()
            }
        },
        onPhoneBlur() {
            console.log(arguments)
        }
    },
    data() {
        return {
            defaultOrganisation: {
                billingEqualsPhysical: true,
                billingAbn: '',
                billingName: '',
                billingAddress: {},
                website: '',
                phone: { ...this.defaultPhoneNumber },
                fax: { ...this.defaultPhoneNumber }
            },
            defaultPhoneNumber: {
                countryCode: '',
                phone: '',
                archived: false
            }
        }
    },
    computed: {
        phoneWebsiteForm: {
            get() {
                console.log('form get: ', this.value)
                return this.value || this.defaultOrganisation
            },
            set(phoneWebsiteForm) {
                this.$emit('input', phoneWebsiteForm)
            }
        }
    }
}
</script>

<style></style>
