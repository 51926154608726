<template>
    <v-form
        ref="form"
        v-model="isValid"
    >
        <h4 class="font-display font-semibold mt-8 pb-3">Work Force Information</h4>
        <!--For all v-textfields beflore
            - All are "Required"
            - only numbers can be added
        -->
        <div class="flex items-center">
            <div class="min-w-100 mr-2 block font-bold font-display text-xs text-grey-2 uppercase leading-tight mb-2">&nbsp;</div>
            <label class="min-w-200 max-w-250 block font-bold font-display text-xs text-grey-2 uppercase leading-tight mb-2">Individual Head Count</label>
            <label class="min-w-300 max-w-300 block font-bold font-display text-xs text-grey-2 uppercase leading-tight mb-2">Max number working at any one time</label>
        </div>

        <div class="flex items-center">
            <label class="min-w-100 mr-2 block font-bold font-display text-xs text-grey-2 uppercase leading-tight mb-2">Doctor(s)</label>
            <div class="min-w-200 max-w-200">
                <v-text-field
                    dense
                    class="w-16"
                    :rules="[required, numbersOnly, maxNumber, noZeroStart]"
                    v-model="staffNumbersForm.doctorHeadCount"
                    @keydown="numbersOnlyEvent"
                ></v-text-field>
            </div>
            <div class="min-w-300 max-w-300">
                <v-text-field
                    class="w-16"
                    dense
                    :rules="[required, numbersOnly, maxNumber, noZeroStart]"
                    v-model="staffNumbersForm.doctorMaxCount"
                    @keydown="numbersOnlyEvent"
                ></v-text-field>
            </div>
        </div>

        <div class="flex items-center">
            <label class="min-w-100 mr-2 block font-bold font-display text-xs text-grey-2 uppercase leading-tight mb-2">Nurse(s)</label>
            <div class="min-w-200 max-w-200">
                <v-text-field
                    dense
                    class="w-16"
                    :rules="[required, numbersOnly, maxNumber, noZeroStart]"
                    v-model="staffNumbersForm.nurseHeadCount"
                    @keydown="numbersOnlyEvent"
                ></v-text-field>
            </div>
            <div class="min-w-300 max-w-300">
                <v-text-field
                    class="w-16"
                    dense
                    :rules="[required, numbersOnly, maxNumber, noZeroStart]"
                    v-model="staffNumbersForm.nurseMaxCount"
                    @keydown="numbersOnlyEvent"
                ></v-text-field>
            </div>
        </div>

        <div class="flex items-center">
            <label class="min-w-100 mr-2 block font-bold font-display text-xs text-grey-2 uppercase leading-tight mb-2">Allied Health</label>
            <div class="min-w-200 max-w-200">
                <v-text-field
                    dense
                    class="w-16"
                    :rules="[required, numbersOnly, maxNumber, noZeroStart]"
                    v-model="staffNumbersForm.alliedHealthHeadCount"
                    @keydown="numbersOnlyEvent"
                ></v-text-field>
            </div>
            <div class="min-w-300 max-w-300">
                <v-text-field
                    class="w-16"
                    dense
                    :rules="[required, numbersOnly, maxNumber, noZeroStart]"
                    v-model="staffNumbersForm.alliedHealthMaxCount"
                    @keydown="numbersOnlyEvent"
                ></v-text-field>
            </div>
        </div>

        <div class="flex items-center">
            <label class="min-w-100 mr-2 block font-bold font-display text-xs text-grey-2 uppercase leading-tight mb-2">Support Staff</label>
            <div class="min-w-200 max-w-200">
                <v-text-field
                    dense
                    class="w-16"
                    :rules="[required, numbersOnly, maxNumber, noZeroStart]"
                    v-model="staffNumbersForm.supportStaffHeadCount"
                    @keydown="numbersOnlyEvent"
                ></v-text-field>
            </div>
            <div class="min-w-300 max-w-300">
                <v-text-field
                    class="w-16"
                    dense
                    :rules="[required, numbersOnly, maxNumber, noZeroStart]"
                    v-model="staffNumbersForm.supportStaffMaxCount"
                    @keydown="numbersOnlyEvent"
                ></v-text-field>
            </div>
        </div>

    </v-form>
</template>

<script>
import { watch, computed, ref } from '@vue/composition-api'
import { FormEvents, FormRules } from '@/components/shared/mixins/formMixins'

export default {
    name: 'StaffNumbers',
    mixins: [FormRules, FormEvents],
    props: {
        value: {
            type: Object,
            default: () => {}
        }
    },
    setup(props, { emit }) {
        const isValid = ref(true)
        const form = ref({})

        //#region computed properties
        const staffNumbersForm = computed(() => props.value)
        //#endregion

        //#region functions
        const validate = () => {
            console.log('form and valid value', isValid.value, form.value)
            isValid.value = form.value.validate()
            return isValid.value
        }
        //#endregion

        //#region watches
        watch(staffNumbersForm, (updated) => {
            console.log('value from staff', updated)
            emit('input', updated)
        }, {deep: true})
        //#endregion

        return {
            staffNumbersForm,
            isValid,
            form,
            validate
        }
    }
}
</script>
