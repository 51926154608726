<template>
    <div class="container flex">
        <div class="mx-auto max-w-960 pt-4">
            <el-form
                :model="practiceDetailsForm"
                :rules="validationRules"
                ref="practiceDetailsForm"
                class="pd-form"
            >
                <div class="w-full flex">
                    <GoBack :goBack="historyLink()" :forceGoBack="true" />
                    <h1 class="text-black mr-2 mb-2 text-xl lg:text-2xl text-center inline-block align-middle w-8/12" data-cy="practiceTitle">{{title}}</h1>
                </div>
                <div class="w-auto">
                    <PracticeForm
                        v-model="practiceDetailsForm"
                        ref="practiceForm"
                        :editMode="isEditable"
                        :is-reapplying="isReapplying"
                        :readonly="isReadOnly"
                        @change-name="practiceNameChangeHandler"
                        @change-abn="practiceABNChangeHandler"
                    />
                </div>

                <h4 class="font-display font-semibold mt-8 pb-4">Physical Address</h4>
                <div class="flex flex-row w-auto">
                    <div class="grid grid-cols-2 gap-10">
                        <div class="grid grid-cols-1 gap-3">
                            <Address
                                v-model="practiceDetailsForm.organisationPtr.physicalAddress"
                                ref="physicalAddress"
                                class="w-full"
                                :readonly="addressIsReadOnly"
                                @postcode-change="updateMMM"
                                @practice-form-update="practiceFormUpdate"
                            ></Address>
                            <div class="grid grid-cols-2 gap-3 w-full">
                                <div>
                                    <el-form-item prop="mmm" class="mb-2">
                                        <label
                                            class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight"
                                        >Modified Monash Model</label>
                                        <el-select
                                            v-model="practiceDetailsForm.mmm"
                                            class="w-full"
                                            :disabled="isReadOnly || disableMmm"
                                            :class="{'disabled': disableMmm}"
                                            :select-when-unmatched="true"
                                            @select="checkSelected"
                                        >
                                            <el-option
                                                v-for="(item, index) in mmmNames"
                                                :key="`mmm-${index}`"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div>
                                    <el-form-item prop="phn" class="mb-2">
                                        <label
                                            class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight"
                                        >Primary Health Network</label>
                                        <el-autocomplete
                                            class="w-full"
                                            v-model="phn"
                                            placeholder="Search for network"
                                            value-key="label"
                                            :debounce="800"
                                            :disabled="isReadOnly"
                                            :fetch-suggestions="fetchPhnSuggestions"
                                            @select="phnSelected"
                                        ></el-autocomplete>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        <PhoneWebsite
                            v-model="practiceDetailsForm.organisationPtr"
                            ref="phoneWebsite"
                            :readonly="isReadOnly"
                        ></PhoneWebsite>
                    </div>
                </div>
                <div class="flex flex-row w-auto">
                    <el-form-item prop="organisationPtr.isPostalSameAsPhysical">
                        <el-checkbox
                            v-model="practiceDetailsForm.organisationPtr.isPostalSameAsPhysical"
                            data-cy="isPostalSameAsPhysical"
                            :disabled="isReadOnly"
                        >Use physical address for postal address</el-checkbox>
                    </el-form-item>
                </div>
                <div v-if="!practiceDetailsForm.organisationPtr.isPostalSameAsPhysical">
                    <h4 class="font-display font-semibold pb-4">Postal Address</h4>
                    <div class="grid grid-cols-2 gap-10">
                        <Address
                            id="postalAddress"
                            v-model="practiceDetailsForm.organisationPtr.postalAddress"
                            ref="postalAddress"
                            :readonly="isReadOnly"
                        ></Address>
                    </div>
                </div>
                <br/>
                <div class="w-auto">
                    <BillingDetails
                        v-model="practiceDetailsForm.organisationPtr"
                        ref="billingDetails"
                        :readonly="isReadOnly"
                    />
               </div>
                <div class="flex flex-row w-full">
                    <PreExistingAccreditation
                        v-model="practiceDetailsForm"
                        ref="preExistingAccreditationForm"
                        :is-reapplying="isReapplying"
                        :readonly="isReadOnly"
                    />
                </div>
                
                <!-- New section, potentially to move into its own component ?_? -->
                <StaffNumbers
                    v-if="!isEditable || isReapplying"
                    ref="staffNumbersForm"
                    v-model="practiceDetailsForm"
                />
                <!--end of new section -->

                <div v-if="!isEditable">
                    <h4 class="font-display font-semibold mt-8 pb-3">Declaration</h4>
                    <Declaration v-model="practiceDetailsForm" ref="declarationForm" :pdf="tncPdf" />
                </div>
                <div class="mb-6" v-if="!isEditable">
                    <Authorised v-model="practiceDetailsForm" ref="authorisedForm" />
                </div>

                <el-alert v-if="!formValid"
                    :title="validationWarning"
                    :closable="false"
                    type="error"
                    show-icon
                ></el-alert>

                <div
                    class="flex flex-row justify-center mt-8"
                    v-if="!isReadOnly"
                >
                    <router-link
                        v-if="isEditable && !isReapplying"
                        tag="span"
                        class="font-bold el-button uppercase w-40 text-grey-3 font-display el-button--default"
                        :to="{name: 'Staff', params: {id: practiceDetailsForm.organisationPtrId.toString()}}"
                    >cancel</router-link>
                    <el-button
                        class="font-bold uppercase w-40 font-display"
                        type="primary"
                        @click="checkForm('practiceDetailsForm')"
                    >{{saveButtonText}}</el-button>
                </div>
            </el-form>
        </div>

    </div>
</template>

<script>
import PracticeForm from '@/components/practice-details/PracticeForm'
import Address from '@/components/shared/Address'
import PhoneWebsite from '@/components/practice-details/partials/PhoneWebsite'
import BillingDetails from '@/components/practice-details/partials/BillingDetails'
import PreExistingAccreditation from '@/components/practice-details/partials/PreExistingAccreditation'
import GlobalHeader from '@/components/shared/GlobalHeader'
import GET_PRACTICE from '@/graphql/queries/getPractice.gql'
import GET_MMM_POSTCODE from '@/graphql/queries/getMmmPostcode.gql'
import GlobalFooter from '@/components/shared/GlobalFooter'
import { mapState } from 'vuex'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import Declaration from '@/components/partials/Declaration'
import Authorised from '@/components/practice-details/partials/Authorised'
import httpMixin from '@/components/shared/mixins/httpMixin'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import { format, isValid, parseISO } from 'date-fns'
import GoBack from '@/components/shared/GoBack'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import TNC_PDF from '@/assets/QPA_Terms_and_Conditions_of_Certification_QS.pdf'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import StaffNumbers from '@/components/practice-details/partials/StaffNumbers'
import { PAYMENT_TYPES } from '@/config/constants'

export default {
    components: {
        StaffNumbers,
        GoBack, Authorised,
        Declaration,
        GlobalHeader,
        PreExistingAccreditation,
        BillingDetails,
        Address,
        PracticeForm,
        PhoneWebsite,
        GlobalFooter
    },
    mixins: [ httpMixin, BackendHelpers, AuthMixin, MessageDialog ],
    props: {
        isReapplying: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            elPracticeDetailsForm: {},
            formValid: true,
            validationWarning: 'Please check you have completed all required fields. Required fields will be highlighted in red above',
            id: this.$route.params.id,
            loading: true,
            disableMmm: false,
            phn: '',
            validationRules: {
                mmm: [{ required: true, message: 'This field is required', trigger: 'blur' }],
                phn: [{ validator: this.validateInput, trigger: 'blur' }],
            },
            defaultPhoneNumber: {
                countryCode: 'AU',
                phone: '',
                archived: false,
            },
            defaultAddress: {
                unit: '',
                street: '',
                address2: '',
                suburb: '',
                postcode: '',
                state: '',
                lat: null,
                lng: null,
                archived: false,
            },
            defaultPractice: {
                mmm: '',
                phn: '',
                practiceType: 'GP',
                organisationPtrId: '',
                organisationPtr: {
                    phone: {
                        countryCode: 'AU',
                        phone: '',
                        archived: false
                    },
                    fax: {
                        countryCode: 'AU',
                        phone: '',
                        archived: false,
                    },
                    website: '',
                    archived: false,
                    isPostalSameAsPhysical: true,
                    isBillingSameAsPhysical: true,
                    physicalAddress: this.defaultAddress,
                    postalAddress: this.defaultAddress,
                    billingAddress: this.defaultAddress,
                    billingName: '',
                    billingAbn: '',
                    billingContactName: '',
                    billingContactEmail: '',
                    structureOther: '',
                },
                initialSwpe: '',
                pipId: '',
                previousExpiryDate: null,
                previousCertificate: null,
                previousCertificateFolderId: null,
                previousStandard: '',
                previousProvider: '',
                branchOrMain: '',
                isFirstAccreditation: null,
                isReceivingIncentives: null,
                isInPracticeNetwork: false,
                meetsGpDefinition: false,
                isAmds: false,
                status: PAYMENT_TYPES.PaymentPending,
                tncSigned: false,
                authorizationSigned: false,
                previousProviderOther: '',
                doctorHeadCount: '',
                doctorMaxCount: '',
                nurseHeadCount: '',
                nurseMaxCount: '',
                alliedHealthHeadCount: '',
                alliedHealthMaxCount: '',
                supportStaffHeadCount: '',
                supportStaffMaxCount: '',
            },
            tncPdf: TNC_PDF,
        }
    },
    apollo: {
        practice: {
            query: GET_PRACTICE,
            variables() {
                return {
                    id: this.id
                }
            },
            skip() {
                return !this.id
            },
            result({ data }) {
                console.log('We got some result!')
                let practice = camelcaseKeys(data.practice, { deep: true, exclude: ['__typename'] })
                if (practice && practice.mmm == null) {
                    practice.mmm = ''
                } else {
                    practice.mmm -= ''
                }

                practice.hasReapplicationDue = practice.reapplicationsAggregate.aggregate.count > 0
                practice.hasReapplicationPaymentDue = practice.reapplicationsPaymentAggregate.aggregate.count > 0
                practice.hasMultipleAccreditations = practice.accreditationsAggregate.aggregate.count > 1

                this.practice = practice
                if (this.practice && this.practice?.organisationPtr) {
                    if (this.practice.organisationPtr?.physicalAddress == null) {
                        this.practice.organisationPtr.physicalAddress = this.defaultAddress
                    }
                    if (this.practice.organisationPtr?.postalAddress == null) {
                        this.practice.organisationPtr.postalAddress = this.defaultAddress
                    }
                    if (this.practice.organisationPtr?.billingAddress == null) {
                        this.practice.organisationPtr.billingAddress = this.defaultAddress
                    }
                    if (this.practice.organisationPtr?.isPostalSameAsPhysical == null) {
                        this.practice.organisationPtr.isPostalSameAsPhysical = true
                    }
                    if (this.practice.organisationPtr?.isBillingSameAsPhysical == null) {
                        this.practice.organisationPtr.isBillingSameAsPhysical = true
                    }
                    if (!this.practice.organisationPtr?.phone) {
                        this.practice.organisationPtr.phone = {...this.defaultPhoneNumber}
                    }
                    if (!this.practice.organisationPtr?.fax) {
                        this.practice.organisationPtr.fax = {...this.defaultPhoneNumber}
                    }
                    if (!this.practice.organisationPtr?.billingName) {
                        this.practice.organisationPtr.billingName = ''
                    }
                    if (!this.practice.organisationPtr?.billingAbn) {
                        this.practice.organisationPtr.billingAbn = ''
                    }
                }
                const phnName = this.phnNames.find(p => p.value === this.practice.phn)
                console.log(phnName)
                this.phn = phnName != null ? phnName.label : ''

                if (practice.accreditations && practice.accreditations.length) {
                    this.practice.initialSwpe = practice.accreditations[0].swpe
                }
                if (this.practice.previousCertificate?.archived) {
                    this.practice.previousCertificate = null
                }
                this.practiceDetailsForm = this.practice
            }
        },
        mmmPostcodes: {
            query: GET_MMM_POSTCODE,
            variables() {
                return {
                    postcode: this.postcode
                }
            },
            skip() {
                return !this.postcode
            },
            result({ data, loading: queryLoading, networkStatus }) {
                if (queryLoading) return
                const practice = { ...(this.practice || this.practiceDetailsForm) }
                if (practice) {
                    const mmmPostcode = data.mmmPostcodes.find(mp => mp.postcode === practice?.organisationPtr?.physicalAddress?.postcode)
                    if (mmmPostcode !== null && mmmPostcode !== undefined) {
                        practice.mmm -= ''
                    }
                    // now we update it based on the postcode
                    if (practice.organisationPtr && practice.organisationPtr.physicalAddress && practice.organisationPtr.physicalAddress.postcode) {
                        this.updateMMM(practice.organisationPtr.physicalAddress.postcode, true)
                    }
                }
                this.loading = false
            }
        }
    },
    methods: {
        historyLink() {
            return !this.practice || this.practice.organisationPtrId == null ?
                this.isNewUser ?
                    { name: 'dashboard' } :
                    { name: 'PracticeList' } :
                { name: 'Staff', id: this.practice.organisationPtrId}
        },
        validateInput(rule, value, cb) {
            // first we check if we are adding or editing
            if (this.loading) {
                cb()
            } else {
                if (!value) {
                    cb('This field is required')
                } else if (this.isPhnValid({ value: value })) {
                    cb('Select a valid PHN')
                } else {
                    cb()
                }
            }
        },
        isPhnValid(e) {
            return !this.phnNames.find(phn => phn.value.toLowerCase() === e.value.toLowerCase())
        },
        checkSelected() {
            console.log(arguments)
        },
        phnSelected(e) {
            // should only get here if a valid phn is selected
            const phn = this.phnNames.find(p => p.label === e.label)
            this.practiceDetailsForm.phn = phn != null ? phn.value : null
            this.$refs.practiceDetailsForm.validateField('phn')
        },
        async updateMMM(postcode) {
            const mmmPostcodes = [...(this.mmmPostcodes || [])]
            const practice = { ...(this.practice || this.practiceDetailsForm) }
            if (!postcode || !mmmPostcodes.length || !this.$refs.practiceDetailsForm) return
            if (this.$refs.practiceDetailsForm) {
                // the mmm field should be the 0th index since it's the first prop in this form
                this.$refs.practiceDetailsForm.fields[0].resetField()
            }
            // first we get the object from mmmPostcodes based on the passed in postCode
            console.log('these are the mmm postcodes we have')
            const mmmPostcode = mmmPostcodes.find(
                mp => mp.postcode === postcode &&
                    practice.organisationPtr?.physicalAddress?.state === mp.state &&
                    practice.organisationPtr?.physicalAddress?.suburb === mp.town
            )
            if (mmmPostcode && mmmPostcode.postcode !== undefined && mmmPostcode.postcode !== null) {
                this.practiceDetailsForm.mmm = mmmPostcode.mmm - ''
                this.$refs.practiceDetailsForm.mmm = mmmPostcode.mmm - ''
                this.disableMmm = true
            } else {
                this.disableMmm = false
            }
            this.$refs.practiceDetailsForm.validate()
                .then(res => {

                })
                .catch((err) => {
                    console.log('error validating mmm', err)
                })
        },
        practiceNameChangeHandler(value) {
            if (value && !this.practiceDetailsForm.organisationPtr.billingName) {
                this.practiceDetailsForm.organisationPtr.billingName = value
            }
        },
        practiceABNChangeHandler(value) {
            if (value && !this.practiceDetailsForm.organisationPtr.billingAbn) {
                this.practiceDetailsForm.organisationPtr.billingAbn = value
            }
        },
        checkValidated(listToCheck) {
            for (const l of listToCheck) {
                if (typeof l === 'boolean' && !l) return false
                if (l?.constructor.name === 'Array' && l.indexOf(false) > -1) return false
            }
            return true
        },
        checkForm(formName) {
            this.formValid = true
            const formsToValidate = [
                this.$refs?.practiceForm?.validate?.(),
                this.$refs?.physicalAddress?.validate?.(),
                this.$refs?.phoneWebsite?.validate?.(),
                this.$refs?.preExistingAccreditationForm?.validate?.(),
                this.$refs?.billingDetails?.validate?.(),
                this.$refs?.staffNumbersForm?.validate?.(),
                this.$refs?.[formName]?.validate?.()
            ]
            if (this.$refs.declarationForm) {
                formsToValidate.push(this.$refs.declarationForm.validate())
            }
            if (this.$refs.authorisedForm) {
                formsToValidate.push(this.$refs.authorisedForm.validate())
            }

            if (!this.practiceDetailsForm.organisationPtr.isPostalSameAsPhysical) {
                formsToValidate.push(this.$refs.postalAddress.validate())
            }
            // resolving all the validation promises
            Promise.all(formsToValidate)
                .then(resolved => {
                    // need to check all have been validated before continuing
                    if (!this.checkValidated(resolved)) return

                    this.saving = true
                    console.log(resolved)
                    let mutation, convertedPractice, variables, practice, convertedOrganisation
                    let {organisationPtr, accreditations, ...otherProps} = this.practiceDetailsForm
                    let {physicalAddress, billingAddress, postalAddress, phone, fax, ...organisation} = organisationPtr
                    console.log(physicalAddress, billingAddress, postalAddress, phone, fax)
                    practice = otherProps
                    // now we get the practice ready to be saved
                    if (practice ) {
                        delete practice.typename
                        // need to set mmm to be a string
                        practice.mmm += ''
                    }

                    practice = {...practice, ...organisationPtr}
                    if (practice.billingAddress && practice.billingAddress.street === '' || practice.isBillingSameAsPhysical) {
                        practice.billingAddress = null
                    }
                    if (practice.postalAddress && practice.postalAddress.street === '' || practice.isPostalSameAsPhysical) {
                        practice.postalAddress = null
                    }
                    if (physicalAddress && physicalAddress.street === '') {
                        practice.physicalAddress = null
                    } else {
                        practice.physicalAddress = physicalAddress
                    }
                    if (practice.phone && (practice.phone.phone === '' || practice.phone.phone == null)) {
                        practice.phone = null
                    }
                    if (practice.fax && (practice.fax.phone === '' || practice.fax.phone == null)) {
                        practice.fax = null
                    }

                    if (practice.previousExpiryDate) {
                        const previousExpiryDate = typeof practice.previousExpiryDate === 'string' ? parseISO(practice.previousExpiryDate) : practice.previousExpiryDate
                        if (isValid(previousExpiryDate)) {
                            practice.previousExpiryDate = format(previousExpiryDate, 'yyyy-MM-dd')
                        } else {
                            practice.previousExpiryDate = null
                        }
                    }
                    let previousCertificate = practice.previousCertificate
                    let previousCertificateFolderId = practice.previousCertificateFolderId
                    delete practice.previousCertificate
                    delete practice.previousCertificateFolderId

                    convertedPractice = snakecaseKeys(practice || {}, { deep: true, exclude: ['__typename'] })
                    let httpOptions = !this.isReapplying ?
                        this.getPostOrPatchOptions(convertedPractice, 'web.practice') :
                        {
                            method: 'POST',
                            url: `web.practice/${convertedPractice.id}/reapply/`,
                            data: convertedPractice
                        }

                    this.$http(httpOptions)
                        .then(({ data }) => {
                            this.$apollo.provider.defaultClient.clearStore()
                            practice = camelcaseKeys(data, {deep: true})

                            if (!this.isEditable && !practice.isFirstAccreditation && previousCertificate) {
                                return new Promise((resolve, reject) => {
                                    let documentPk = null
                                    this.$http.post('document/', {
                                        file: previousCertificate.name,
                                        name: previousCertificate.name,
                                        practice: practice.id,
                                        folder: previousCertificateFolderId
                                    })
                                        .then(( { data: {pk, urls, timeout } } ) => {
                                            documentPk = pk
                                            const options = {
                                                headers: {
                                                    "content-type": "application/x-www-form-urlencoded"
                                                },
                                            }
                                            return this.$http.put(urls.file, previousCertificate, options)
                                        })
                                        .then((val) => {
                                            // TODO: remove this or do some other clean up if the file failed to upload
                                            // now we post the updated values to django to store
                                            const requests = []
                                            
                                            let data = {
                                                id: documentPk,
                                                file_size: previousCertificate.size,
                                                file_type: previousCertificate.type
                                            }
                                            let httpOptions = this.getPostOrPatchOptions(data, 'document', false, true)
                                            requests.push(this.$http(httpOptions))

                                            data = snakecaseKeys({
                                                id: practice.id,
                                                previousCertificate: documentPk,
                                            }, {deep: true})
                                            httpOptions = this.getPostOrPatchOptions(data, 'web.practice')
                                            requests.push(this.$http(httpOptions))
                                            
                                            return Promise.all(requests)

                                        })
                                        .then((val) => {
                                            resolve(practice)
                                        })
                                        .catch((err) => {
                                            console.log(err)

                                            if (documentPk != null) {
                                            this.$http
                                                .delete(`document/${documentPk}/`)
                                                .then((response) => {
                                                    return this.$http.delete(`web.practice/${practice.id}/`)
                                                })
                                                .then((response) => {
                                                    console.log('delete response', response)
                                                })
                                                .catch(err => err)
                                            }

                                            reject('Failed to upload previous certificate file. Please try again.')
                                        })
                                })
                            } else {
                                return Promise.resolve(practice)
                            }
                        })
                        .then((practice) => {
                            if (!this.isEditable || this.isReapplying) {
                                this.goToBackendUrl(practice.applicationPaymentUrl)
                            } else {
                                this.$router.push({name: 'Staff', params: { id: this.practiceDetailsForm.organisationPtrId + '' }})
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            if (err) {
                                this.type = 'error'
                                this.message = err
                                this.showMessage({duration: 5000})
                            }
                        })
                        .finally(() => {
                            this.saving = false
                        })
                })
                .catch((valid) => {
                    // this should always return false since it is checking if the form is valid
                    this.formValid = valid
                })
        },
        fetchPhnSuggestions(queryString, cb) {
            queryString = queryString || ''
            const filteredList = this.phnNames.filter(phn => {
                return phn.value.toLowerCase().includes(queryString.toLowerCase())
            })
            cb(filteredList)
        },
        practiceFormUpdate(e) {
            console.log(e)
        },
        initComponent() {
            this.practice = {...this.defaultPractice}
            this.$refs.practiceForm.resetFields()
            this.$refs.practiceDetailsForm.resetFields()
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        mmmNames() {
            return this.staticChoices['practice.mmm'] || []
        },
        phnNames() {
            return this.staticChoices['practice.phn'] || []
        },
        practiceDetailsForm: {
            get() {
                const practice = this.practice
                return practice || this.defaultPractice
            },
            set(practiceDetailsForm) {
                this.$emit('input', practiceDetailsForm)
            }
        },
        isEditable() {
            return this.practiceDetailsForm.organisationPtrId != null && this.practiceDetailsForm.organisationPtrId !== ''
        },
        title() {
            if (this.isReadOnly) {
                return 'View Practice'
            }
            if (this.isReapplying) {
                return 'Review details for Renewal'
            }
            return this.isEditable ? 'Edit practice' : 'Add practice'
        },
        saveButtonText() {
            return !this.isReapplying ? (this.isEditable ? 'Save practice' : 'Next') : 'Next'
        },
        isReadOnly() {
            return this.isEditable && this.practiceDetailsForm.hasReapplicationPaymentDue
        },
        addressIsReadOnly() {
            return this.isReadOnly || (this.isEditable && !this.isAdmin && !this.isStaff && !this.isReapplying)
        },
        postcode() {
            return  this.practiceDetailsForm?.organisationPtr?.physicalAddress?.postcode
        }
    },
    mounted() {
        if (this.practice && this.practice.organisationPtr && this.practice.organisationPtr.physicalAddress) {
            this.updateMMM(this.practice.organisationPtr.physicalAddress.postcode)
        }
    },
    watch: {
        '$route.path': function(val, oldVal){
            console.log(this.$route.params.id)
            if (this.$route.params.id == null) {
                this.initComponent()
            }
        },
        practice() {
            if (!this.practice && this.id) {
                this.$router.push({name: 'PracticeList'})
            }
            if (this.isReapplying && !this.practice.hasReapplicationDue) {
                this.$router.push({name: 'PracticeList'})
            }
        },
    }
}
</script>
