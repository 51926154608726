<template>
    <div class="p-details w-full">
        <el-form
            :model="preExistingAccreditationForm"
            :rules="validationRules"
            ref="preExistingAccreditationForm"
            class="pd-form"
        >
            <h4 class="font-display font-semibold mt-8 pb-3">Pre-existing Accreditation</h4>
            <div
                v-if="!preExistingAccreditationForm.organisationPtrId"
                class="flex flex-row w-auto"
            >
                <el-form-item prop="isFirstAccreditation" class="mb-0">
                    <div class="inline-block align-middle text-sm">
                        <span>Is this the practice's first accreditation?</span>
                        <el-popover
                            placement="top-start"
                            title="First Accreditation"
                            width="200"
                            trigger="hover"
                            content="Has your practice or service previously undertaken accreditataion against the RACGP standards"
                        >
                            <fa-icon icon="question-circle" slot="reference" class="grey-1 ml-2"/>
                        </el-popover>
                    </div>
                    <el-radio-group v-model="preExistingAccreditationForm.isFirstAccreditation">
                        <el-radio :label="true" class="pn-radio" data-cy="practiceAccreditationYes" :disabled="readonly">Yes</el-radio>
                        <el-radio :label="false" class="pn-radio" data-cy="practiceAccreditationNo" :disabled="readonly">No</el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div v-if="!preExistingAccreditationForm.organisationPtrId && !preExistingAccreditationForm.isFirstAccreditation" class="mt-1 w-full" data-cy="firstAccreditationSection">
                <v-form ref="firstAccreditation" v-model="isValid" class="grid grid-cols-4 gap-x-4 w-full">
                    <div class="col-span-1 grow-0">
                        <v-select
                            label="Current Provider"
                            class="currentProvider"
                            attach=".currentProvider"
                            :items="providerOptions"
                            v-model="preExistingAccreditationForm.previousProvider"
                            item-text="label"
                            item-value="value"
                            :rules="[required]"
                        ></v-select>
                    </div>
                    <div v-if="showPreviousProviderOther" class="col-span-1">
                        <v-text-field
                            v-model="preExistingAccreditationForm.previousProviderOther"
                            :readonly="readonly"
                            label="Provider name"
                        ></v-text-field>
                    </div>
                    <div class="col-span-1">
                        <div class="mt-1 datepickerFloat">
                            <EditableDatePicker
                                class="expiryDate" 
                                v-model="preExistingAccreditationForm.previousExpiryDate"
                                label="Expiry Date"
                                :rules="[required]"
                            />
                        </div>
                    </div>
                    <div class="col-span-1">
                        <v-select
                            class="previousStandard"
                            :items="previousStandards"
                            attach=".previousStandard"
                            label="Standard certified to"
                            v-model="preExistingAccreditationForm.previousStandard"
                            :rules="[required]"
                        ></v-select>
                    </div>

                    <div v-if="!preExistingAccreditationForm.organisationPtrId" class="col-span-3">
                        <v-file-input
                            label="Certificate File (PDF, JPG, PNG)"
                            truncate-length="15"
                            accept="image/jpeg,image/png,application/pdf,image/gif"
                            :rules="[required]"
                            @change="handlePreviousCertificateChange"
                        ></v-file-input>
                    </div>
                </v-form>
            </div>
            <div class="flex flex-row w-auto">
                <el-form-item prop="isReceivingIncentives" class="mb-2">
                    <div class="inline-block align-middle text-sm">
                        <span>Is the practice currently receiving incentive payments?</span>
                        <el-popover
                            placement="top-start"
                            title="Incentive payments"
                            width="200"
                            trigger="hover"
                            content="Incentive payments include (PIP) or Practice Incentive Payments from Services Australia as an accredited general practice."
                        >
                            <fa-icon icon="question-circle" slot="reference" class="grey-1 ml-2"/>
                        </el-popover>
                    </div>
                    <el-radio-group v-model="preExistingAccreditationForm.isReceivingIncentives">
                        <el-radio :label="true" class="pn-radio" :disabled="readonly">Yes</el-radio>
                        <el-radio :label="false" class="pn-radio" :disabled="readonly">No</el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="flex flex-row w-auto">
                <div class="grid grid-cols-2 gap-10" v-if="preExistingAccreditationForm.isReceivingIncentives">
                    <div class="grid grid-cols-2 gap-2">
                        <div>
                            <el-form-item prop="initialSwpe" class="mb-2">
                                <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">SWPE</label>
                                <el-input v-model="preExistingAccreditationForm.initialSwpe" :readonly="readonly"></el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item prop="pipId" class="mb-2">
                                <label
                                    class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight"
                                >PIP ID</label>
                                <el-input v-model="preExistingAccreditationForm.pipId" :readonly="readonly"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import FileMethods from '@/components/shared/mixins/fileMethods'
import { PREV_ACCRED_FOLDER_CODE, STAFF_DOCUMENT_FOLDER_CODE } from '@/config/constants'
import FolderMixin from '@/components/shared/mixins/folderMixin'
import EditableDatePicker from '@/components/partials/EditableDatePicker'
import { FormRules } from '@/components/shared/mixins/formMixins'

export default {
    name: 'pre-existing-accreditation',
    components: {EditableDatePicker},
    mixins: [FileMethods, FolderMixin, FormRules],
    props: {
        value: {
            type: Object,
            default: () => {
            }
        },
        isReapplying: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dateFormat: 'dd/MM/yyyy',
            validationRules: {
                isFirstAccreditation: [{ required: true, message: 'This field is required', trigger: 'change' }],
                isReceivingIncentives: [{ required: true, message: 'This field is required', trigger: 'change' }],
                previousProviderOther: [{ required: true, message: 'This field is required', trigger: 'change' }],
            },
            providerOptions: [{
                value: 'AGPAL',
                label: 'AGPAL'
            }, {
                value: 'QPA',
                label: 'QPA'
            }, {
                value: 'OTHER',
                label: 'Other'
            }],
            documentFolderId: 0,
            getVisibleFolders: true,
            practiceId: null,
            emptyHidden: true,
            isValid: true
        }
    },
    beforeMount() {
        this.loadDocumentFolderCode()
    },
    mounted() {
        if (!this.value.organisationPtrId) {
            this.validationRules.previousCertificate = [{ required: true, message: 'This field is required', trigger: 'blur' }]
        }
    },
    methods: {
        validate() {
            if (this.$refs.firstAccreditation && !this.validateFirstAccredForm()) return false

            return this.$refs.preExistingAccreditationForm.validate()
        },
        validateFirstAccredForm() {
            if (this.preExistingAccreditationForm.isFirstAccreditation) return this.isValid = true
            return this.isValid = this.$refs.firstAccreditation.validate()
        },
        handlePreviousCertificateChange(file) {
            console.log('file uploaded', file)
            this.preExistingAccreditationForm.previousCertificate = file
            this.preExistingAccreditationForm.previousCertificateFolderId = file ? this.documentFolderId : null

            this.$emit('input', this.preExistingAccreditationForm)
        },
        handlePreviousCertificateRemove(file) {
            this.preExistingAccreditationForm.previousCertificate = null
            this.$emit('input', this.preExistingAccreditationForm)
        },
        async loadDocumentFolderCode() {
            const prevAccredFolder = await this.getFolderByCode(PREV_ACCRED_FOLDER_CODE)
            console.log('did we find the right code?', prevAccredFolder)
            if (prevAccredFolder) this.documentFolderId = prevAccredFolder.id
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            folders: state => state.app.folders,
        }),
        previousStandards() {
            return this.staticChoices['practice.previous_standard'] || []
        },
        preExistingAccreditationForm: {
            get() {
                const practice = this.value

                return practice || {}
            },
            set(preExistingAccreditationForm) {
                this.$emit('input', preExistingAccreditationForm)
            }
        },
        showFirstAccordion() {
            return !this.isReapplying &&
                !this.preExistingAccreditationForm?.hasMultipleAccreditations &&
                !this.preExistingAccreditationForm?.isFirstAccreditation
        },
        showPreviousProviderOther() {
            return this.preExistingAccreditationForm.previousProvider &&
                this.preExistingAccreditationForm.previousProvider.toLowerCase() === 'other'
        }
    }
}
</script>
