<template>
    <el-form
        :model="addressForm"
        :rules="validationRules"
        ref="addressRef"
        class="pd-form"
    >
        <div class="address">
            <div class="grid grid-cols-6 gap-2">
                <div class="col-span-6" v-if="!readonly">
                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Search</label>
                    <el-form-item class="mb-2" prop="googleAddress">
                        <div class="el-input with-search-icon">
                            <i class="el-input__icon el-icon-search"></i>
                            <gmap-autocomplete
                                :id="id"
                                class="w-full el-input__inner gmap-search"
                                placeholder="Enter an address"
                                :options="autocompleteOptions"
                                :select-first-on-enter="true"
                                :readonly="readonly"
                                @place_changed="getAddressData"
                                @keyup="autoCompleteKeyupHandler($event)"
                            >
                            </gmap-autocomplete>
                        </div>
                    </el-form-item>
                </div>
                <div class="col-span-1">
                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Unit</label>
                    <el-form-item prop="unit" class="mb-2">
                        <el-input v-model="addressForm.unit" @blur="manualAddressChange" :readonly="readonly"></el-input>
                    </el-form-item>
                </div>
                <div class="col-span-5">
                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Address line 1</label>
                    <el-form-item prop="street" class="mb-2">
                        <el-input :id="`${id}-street`" v-model="addressForm.street" @blur="manualAddressChange" :readonly="readonly"></el-input>
                    </el-form-item>
                </div>
                <div class="col-span-6">
                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Address line 2</label>
                    <el-form-item prop="address2" class="mb-2">
                        <el-input v-model="addressForm.address2" @blur="manualAddressChange" :readonly="readonly"></el-input>
                    </el-form-item>
                </div>
                <div class="col-span-2">
                    <label
                        class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Suburb</label>
                    <el-form-item prop="suburb" class="mb-2">
                        <el-input v-model="addressForm.suburb" @blur="manualAddressChange" :readonly="readonly"></el-input>
                    </el-form-item>
                </div>
                <div class="col-span-2">
                    <label
                        class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Postcode</label>
                    <el-form-item prop="postcode" class="mb-2">
                        <el-input v-model="addressForm.postcode" @blur="updatePostcode" :readonly="readonly"></el-input>
                    </el-form-item>
                </div>
                <div class="col-span-2">
                    <label
                        class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">State</label>
                    <el-form-item prop="state" class="mb-2">
                        <el-select v-model="addressForm.state" @focus="manualAddressChange" :disabled="readonly">
                            <el-option
                                v-for="(item, index) in states"
                                :key="`state-${index}`"
                                :label="item.value"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useState } = createNamespacedHelpers('app')

const DEFAULT_ADDRESS = {
    unit: '',
    street: '',
    address2: '',
    suburb: '',
    postcode: '',
    state: '',
    lat: null,
    lng: null,
    archived: false,
}

export default {
    name: 'AddressComponent',
    props: {
        id: {
            type: String,
            default: 'address'
        },
        value: {
            type: Object,
            required: true,
            default: () => {
                return {
                    unit: '',
                    street: '',
                    address2: '',
                    suburb: '',
                    postcode: '',
                    state: '',
                    lat: null,
                    lng: null,
                    archived: false,
                }
            }
        },
        title: {
            type: String,
            default: ''
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input', 'postcode-change'],
    setup(props, { emit }) {
        const addressRef = ref({})
        const googleAddress = ref('')
        const defaultAddress = ref({ ...DEFAULT_ADDRESS })
        const { staticChoices } = useState(['staticChoices'])
        const validationRules = ref({
            unit: [{ required: false, message: 'Field required', trigger: 'blur' }],
            street: [{ required: true, message: 'Field required', trigger: 'blur' }],
            address2: [{ required: false, message: 'Field required', trigger: 'blur' }],
            suburb: [{ required: true, message: 'Field required', trigger: 'blur' }],
            postcode: [{ required: true, message: 'Field required', trigger: 'blur' }],
            state: [{ required: true, message: 'Field required', trigger: 'blur' }]
        })
        const autocompleteOptions = ref({
            componentRestrictions: {
                country: [
                    'au'
                ]
            }
        })
        const geoCodedAddressTimer = ref(null)
        const addressFound = ref(true)
        const initialPageLoad = ref(true)
        const google = computed(() => getGoogleMapsAPI)
        const states = computed(() => staticChoices.value?.['generic_address.state'])
        const addressForm = computed({
            get() {
                return props.value || defaultAddress.value
            },
            set(addressForm) {
                emit('input', addressForm)
            }
        })
        const localAddress = computed({
            get() {
                console.log('getting localAddress so sending addressForm')
                return addressForm.value
            },
            set(addressData) {
                let addressObject = mapAddressData(addressData.address_components)
                if (addressData) {
                    if (addressObject.streetNumber != null && addressObject.street != null) {
                        addressForm.value.street = addressObject.streetNumber + ' ' + addressObject.street
                    }
                    addressForm.value.suburb = addressObject.suburb
                    addressForm.value.state = addressObject.state
                    addressForm.value.postcode = addressObject.postcode
                    addressForm.value.lng = addressData.geometry.location.lng()
                    addressForm.value.lat = addressData.geometry.location.lat()

                    updatePostcode(addressForm.value.postcode)
                    addressFound.value = true
                } else {
                    addressFound.value = false
                }
                const address = {...addressForm.value}
                emit('input', address)
            }
        })

        const validate = () => {
            return addressRef.value?.validate()
        }
        const getAddressData = (addressData) => {
            addressRef.value?.resetFields?.()
            localAddress.value = { ...addressData }

            validate()
        }
        const updatePostcode = (value) => {
            emit('postcode-change', value)
        }

        const mapAddressData = (addressList) => {
            const mappedObj = { ...defaultAddress.value }
            if (!addressList) return null

            for (let i = 0; i < addressList.length; i++) {
                let key = ''
                switch (addressList[i].types[0]) {
                    case 'street_number':
                        key = 'streetNumber'
                        break
                    case 'route':
                        key = 'street'
                        break
                    case 'locality':
                        key = 'suburb'
                        break
                    case 'administrative_area_level_1':
                        key = 'state'
                        break
                    case 'postal_code':
                        key = 'postcode'
                        break
                }
                mappedObj[key] = addressList[i].short_name
            }
            return mappedObj
        }

        const geocodedAddress = () => {
            let address = `${localAddress.value.street || ''} ${localAddress.value.address2 || ''}, ${localAddress.value.suburb || ''} ${localAddress.value.state || ''}, ${localAddress.value.postcode || ''}`
            let geocoder = new google.value.maps.Geocoder()

            geocoder.geocode({
                'address': address
            }, (results, status) => {

                if (status === google.value?.maps?.GeocoderStatus?.OK) {
                    const latitude = results[0].geometry.location.lat()
                    const longitude = results[0].geometry.location.lng()

                    localAddress.value.lat = addressForm.value.lat = latitude
                    localAddress.value.lng = addressForm.value.lng = longitude
                }
            })
        }

        const autoCompleteKeyupHandler = (event) => {
            addressFound.value = !!event.target.value
        }

        const manualAddressChange = () => {
            localAddress.value.lat = null
            localAddress.value.lng = null
        }

        onMounted(() => {
            addressFound.value = false
        })

        watch(addressForm, (newAddressForm) => {
            // if it's the initial page load then we stop what we're doing cause we don't need the geocoded stuff
            if (initialPageLoad.value) {
                initialPageLoad.value = false
                return
            }
            // we only do this if we didn't get the value from google maps
            if (geoCodedAddressTimer.value) clearTimeout(geoCodedAddressTimer.value)
            if (!addressFound.value) {
                geoCodedAddressTimer.value = setTimeout(() => {
                    geocodedAddress()
                }, 3000)
            }

            if (newAddressForm && newAddressForm?.street !== '') emit('input', newAddressForm)
        }, { deep: true })

        return { addressRef, validationRules, autocompleteOptions, geoCodedAddressTimer, addressFound, initialPageLoad,
            staticChoices, states, addressForm, manualAddressChange,
            validate, updatePostcode, getAddressData, geocodedAddress, autoCompleteKeyupHandler }
    }
}
</script>
