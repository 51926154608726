<template>
    <el-form
        :model="billingDetailsForm"
        :rules="validationRules"
        ref="billingDetailsForm"
        class="pd-form"
    >
        <div class="billing-details">
            <h4 class="font-display font-semibold mt-8 pb-4">Billing Details</h4>
            <div class="grid grid-cols-12 gap-2">
                <div class="flex-1 col-span-3">
                    <el-form-item prop="billingName" class="mb-2">
                        <label
                            class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight"
                        >Billing entity name</label>
                        <el-input v-model="billingDetailsForm.billingName" :readonly="readonly"></el-input>
                    </el-form-item>
                </div>
                <div class="flex-1 col-span-3">
                    <label
                        class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight"
                    >Billing entity ABN</label>
                    <el-form-item prop="billingAbn" class="mb-2">
                        <el-input v-model="billingDetailsForm.billingAbn" :readonly="readonly"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-2 my-4">
                <div class="flex-1 col-span-3">
                    <label
                        class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight"
                    >Primary billing contact name</label>
                    <el-form-item prop="billingContactName" class="mb-2">
                        <el-input v-model="billingDetailsForm.billingContactName" :readonly="readonly"></el-input>
                    </el-form-item>
                </div>
                <div class="flex-1 col-span-3">
                    <label
                        class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight"
                    >Primary billing contact email</label>
                    <el-form-item prop="billingContactEmail" class="mb-2">
                        <el-input v-model="billingDetailsForm.billingContactEmail" :readonly="readonly"></el-input>
                    </el-form-item>
                </div>
            </div>
            <el-form-item prop="isBillingSameAsPhysical">
                <el-checkbox 
                    v-model="billingDetailsForm.isBillingSameAsPhysical" 
                    data-cy="isBillingSameAsPhysical"
                    :disabled="readonly"
                >Use physical address for billing address</el-checkbox>
            </el-form-item>
            <div v-if="!billingDetailsForm.isBillingSameAsPhysical">
                <h3 class="font-display pb-4">Billing Address</h3>
                <div class="grid grid-cols-2 gap-10">
                    <Address
                        id="billingAddress"
                        v-model="billingDetailsForm.billingAddress"
                        ref="billingAddress"
                        :readonly="readonly"
                    ></Address>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import Address from '@/components/shared/Address'
import abnValidator from '@/components/shared/mixins/abnValidator'

export default {
    name: 'billing-details',
    components: { Address },
    mixins: [abnValidator],
    props: {
        value: {
            type: Object,
            required: true,
            default: () => {
                return this.defaultOrganisation
            }
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        validate() {
            const formValidations = []
            // we have to check if the isBillingSameAsPhysical value is set because, if it's not
            // the billing address for doesn't exist yet
            if (!this.billingDetailsForm.isBillingSameAsPhysical) {
                formValidations.push(this.$refs.billingAddress.validate())
            }
            formValidations.push(this.$refs.billingDetailsForm.validate())
            return Promise.all(formValidations)
        }
    },
    data() {
        return {
            defaultAddress: {
                unit: '',
                street: '',
                address2: '',
                suburb: '',
                postcode: '',
                state: '',
                lat: '',
                lng: '',
                archived: false
            },
            defaultOrganisation: {
                isBillingSameAsPhysical: true,
                billingAbn: '',
                billingName: '',
                billingContactName: '',
                billingContactEmail: '',
                billingAddress: this.defaultAddress
            },
            validationRules: {
                billingAbn: [{ validator: this.abnAcnValidator, trigger: 'blur' }],
                billingName: [{ required: true, message: 'This field is required', trigger: 'blur' }],
                billingContactName: [{ required: true, message: 'This field is required', trigger: 'blur' }],
                billingContactEmail: [{ required: true, message: 'This field is required', trigger: 'blur' }],
                isBillingSameAsPhysical: [{ required: true, message: 'This field is required', trigger: 'change' }],
            }
        }
    },
    computed: {
        billingDetailsForm: {
            get() {
                return this.value || this.defaultOrganisation
            },
            set(billingDetailsForm) {
                this.$emit('input', billingDetailsForm)
            }
        }
    }
}
</script>
