<template>
    <el-form
        :model="declarationForm"
        :rules="validationRules"
        ref="declarationRef"
        class="pd-form w-full"
    >
        <el-form-item prop="tncSigned" class="mb-0">
            <el-checkbox v-model="declarationForm.tncSigned">
            </el-checkbox>
            <div class="inline-block align-middle text-sm pl-3">
                <span>I accept the <a :href="tncPdf" target="_blank" class="cursor-pointer text-sm font-bold text-primary-light hover:text-primary">{{tncText}}</a></span>
            </div>
        </el-form-item>

    </el-form>
</template>

<script>
import Validators from '@/components/shared/mixins/validators'

export default {
    name: 'DeclarationComponent',
    mixins: [Validators],
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        tncText: {
            type: String,
            default: 'Terms and Conditions of certification'
        },
        pdf: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            validationRules: {
                tncSigned: [{ validator: this.checkboxValidator, trigger: 'change' }],
            },
            branchOptions: [{
                value: true,
                label: 'Branch'
            }, {
                value: false,
                label: 'Main practice'
            }],

        }
    },
    methods: {
        validate(){
            return this.$refs.declarationRef.validate()
        },
    },
    computed: {
        declarationForm: {
            get() {
                return this.value || {}
            },
            set(declarationForm) {
                this.$refs.declarationRef = {...this.$refs.declarationRef, ...declarationForm}
                this.$emit('input', declarationForm)
            }
        },
        tncPdf() {
            return this.pdf || ''
        }
    },
}
</script>

<style scoped>

</style>
