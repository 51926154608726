<template>
    <el-form
        :model="validationForm"
        :rules="validationRules"
        ref="validationForm"
        class="pd-form"
    >
        <el-form-item prop="authorizationSigned">
            <el-checkbox v-model="validationForm.authorizationSigned">
            </el-checkbox>
            <div class="inline-block align-middle text-sm pl-3">
                <span>I confirm that I am authorised to apply for accreditation for this practice</span>
            </div>
        </el-form-item>
    </el-form>
</template>

<script>
import Validators from '@/components/shared/mixins/validators'

export default {
    name: 'AuthorisedComponent',
    mixins: [Validators],
    props: {
        value: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
        validate(){
            return this.$refs.validationForm.validate()
        },
    },
    data() {
      return {
          validationRules: {
              authorizationSigned: [{ validator: this.checkboxValidator, trigger: 'blur' }],
          },
      }
    },
    computed: {
        validationForm: {
            get() {
                return this.value || {}
            },
            set(validationForm) {
                this.$emit('input', validationForm)
            }
        },
    },
}
</script>

<style scoped>

</style>
