import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],ref:"dateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290","attach":".datepickerFloat"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({ref:"date",attrs:{"label":_vm.label,"rules":_vm.rules,"hint":_vm.hint,"persistent-hint":_vm.showHint,"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"change":_vm.dateChanged},model:{value:(_vm.computedDate),callback:function ($$v) {_vm.computedDate=$$v},expression:"computedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c(VDatePicker,_vm._b({ref:"datePicker",attrs:{"no-title":"","color":"primary","max":_vm.max},on:{"change":_vm.save},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-date-picker',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }